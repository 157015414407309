/*=============================================================================

  The MakePlans Sass document!

=============================================================================*/

@import "normalize";
@import "font-awesome.scss";

// * {
//   outline: 1px solid red;
//   & > * {
//     outline: 1px solid blue;
//     & > * {
//       outline: 1px solid green;
//       & > * {
//         outline: 1px solid yellow;
//         & > * {
//           outline: 1px solid orange;
//           & > * {
//             outline: 1px solid hotpink;
//           }
//         }
//       }
//     }
//   }
// }

/*=============================================================================
  Includes, mixins and grid setup
=============================================================================*/

// Clearfix
%cf {
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Image replacement setup
%ir {
  overflow: hidden;
  padding: 0;
  text-indent: -999em;
  text-decoration: none;
  display: block;
}

// Browser prefixes made easy
@mixin prefix($property, $value...){      // Chews experimental css properties,
    -webkit-#{$property}:   $value;       // spits out browser prefixes.
    -moz-#{$property}:      $value;       // Does not include -o- and -ms- because,
    #{$property}:           $value;       // you know ... They're useless.
}

// Box sizing made easy
@mixin box-sizing($box: border-box) {     // border-box or content-box (padding-box is wtf)
    @include prefix(box-sizing, $box);
}

// Gradients for almost everyone!
@mixin gradient($from, $to, $dir:"vert") {
// fallback/image non-cover color
  background-color: darken($from, 5%);

  @if $dir == "vert" {
  // Firefox 3.6+
    background-image: -moz-linear-gradient($from, $to);
  // Safari 4+, Chrome 1+
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
  // Safari 5.1+, Chrome 10+
    background-image: -webkit-linear-gradient($from, $to);
    background-image: linear-gradient($from, $to);
  // Opera 11.10+
    background-image: -o-linear-gradient($from, $to);
  // Old IEs
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($from)}', endColorstr='#{ie-hex-str($to)}');
  } @else if $dir == "hor" {
    background: -moz-linear-gradient(left, $from 0%, $to 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$from), color-stop(100%,$to));
    background: -webkit-linear-gradient(left, $from 0%,$to 100%);
    background: -o-linear-gradient(left, $from 0%,$to 100%);
    background: linear-gradient(to right, $from 0%,$to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
}

// Hacks
.calicon img {
  margin-left: -120px;
  position: relative;
  left: 50%;
}

// Colors
$brand-primary:                      #bb4e45;
$brand-secondary:                    #308296;
$text-color:                         #333;
$text-lighter:                       #666;
$text-on-dark:                       #fff;
$brand-primary-dilute:               #e4b8b5;
$brand-secondary-dilute:             #accdd5;

// Sizes
$fontsize:                  16px;                // Assume 16 pixels to be "standard" browser font (1 em / 100%)
$baseline:                  1.5;                 // Baseline ratio, used to calculate line heights and margins
$lineheight:                $fontsize * $baseline;// Default line height, used to calculate bigger line heights
$rem:                       calc($fontsize / 1rem);    // $em and $rem used for calculations
$em:                        calc($fontsize / 1em);

// Fonts
$text-font:                 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@mixin font-medium {
  font-weight: 600;
//  font-family: "Lucida Grande", sans-serif;
}
@mixin font-bold {
  font-weight: 700;
//  font-family: "Lucida Grande", sans-serif;
}

// Layout
$column-count:              12;                   // Amount of columns
$column-width:              calc(100% / $column-count);// Width
$column-gutter:             1%;                  // Gutter width / 2
$box-padding:               20;                  // Padding for content boxes
$box-bottom-margin:         20;                  // Bottom margin for content boxes

// Breakpoints
$big:                       1280px;
$medium:                    720px;
$small:                     520px;

%row {
  @extend %cf;
  clear: both;
  position: relative;
}

%col {
  @include box-sizing(border-box);
  float: left;
  padding: 0 calc($column-gutter/2);

  // @media (max-width: $small) {
  //   float: none;
  //   padding: 0;
  // }
}

@mixin column($cols, $force: 'false') {
  width: ($cols * $column-width);

  // Modify this to suit your needs
  // For example, if you have more or fewer columns, change the ‘drop’
  // here. Also, if you want it to drop at a different ratio,
  // this is the place.
  @media (max-width: $medium) {
    @if $force == 'true' {
    } @else {
      width: $column-count * $column-width;
    }
  }

  // This assumes that your mobile layout will be one column only.
  // If you want to maintain columns, this is the place to tweak
  // the variables. For example, a forced 50% split can be
  // created this way.
  @media (max-width: $small) {
    @if $force == 'true' {
      width: 50%;
    } @else {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@mixin offset($offset) {
  // This offsets the active element with the amount of columns you enter.
  // $offset should only take integers and counts columns. We don’t do
  // finer granularity than this, to keep things simple and attractive.
  margin-left: $offset * $column-width;

  @media (max-width: $medium) {
    margin-left: 0;
  }
}

/*=============================================================================
  Site-wide elements
=============================================================================*/

// Layout values for easier interoperability
//
// Navigation bar is fixed, so needs offset from the body to not overlap
// the topmost elements. Also, on the features page there is a second
// fixed navbar, which needs to have the same offset from the top.
$nav-height:      2.8em;

body {
  background: #fff;
  color: $text-color;
  font: normal #{$fontsize}/#{$baseline} $text-font;
  padding-top: $nav-height;
  text-rendering: optimizeLegibility;

  @media screen and (max-width: $medium) {
    padding-top: 0;
  }
}

#page›front {
  padding-top: 0;
}

::selection {
  background-color: $brand-secondary;
  color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

#page›front a.underline {
  border-color: 2px solid rgba($text-on-dark, .4);
}

h1 {
  line-height: 1;
}

h2 {
  line-height: 1.125;
}

h3 {
  line-height: 1.25;
}

h4 {
  margin: 1.5rem 0 0;
}

p a,
h1 a,
h2 a {
  border-bottom: 2px solid rgba($text-color, .4);
}

@media screen and (max-width: $medium) {
  h2 br {
    display: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  border: 0;
  border-top: 1px solid lighten($text-color, 50%);
  margin: 2em auto;
  max-width: $site-max-width;
}

ul {
  padding-left: 0;

  li {
    margin-left: 24px;
  }
}

.flex-content {
  display: flex;

  .flex-item {
    flex: 1;
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.button {
  @include prefix(box-shadow, 0 2px 0 rgba(0,0,0,.125));
  background: $brand-secondary;
  color: $text-on-dark;
  border-radius: 3px;
  border: none;
  font-weight: 800;

  &:hover {
    background: darken($brand-secondary,2.5%);
  }

  &.button›solo {
    clear: both;
    display: block;
    @extend %col;
    @include column(4);
    @include offset(4);
    text-align: center;
    padding: .3em;
    font-size: 1.25em;
    @include font-medium;
  }

  &.button›duo {
    clear: both;
    display: block;
    text-align: center;
    padding: .8em .3em;
    font-size: 1.25em;
    @include font-medium;
    line-height: 1.2;
    margin-top: 1.5em;
    margin-bottom: 2em;
    background: darken($brand-primary,10%);
    @include prefix(box-shadow, 0 2px 0 darken($brand-primary,20%));

    &:hover {
      background: darken($brand-primary,12.5%);
    }

    b {
      display: block;
    }

    small {
      opacity: .8;
      font-weight: 400;
      font-size: 75%;
    }

    @media screen and (max-width: $small) {
      font-size: 1em;
    }

  }
}

.center {
  text-align: center;
}

.center img {
  margin: auto;
}

.inline {
  display: inline;
}

h2 {
  @include font-medium;
}

h2.big-title {
  font-size: 2em;
  line-height: 1.2;
  margin-top: 1.33em;
  margin-bottom: .33em;

  @media screen and (max-width: $medium) {
    font-size: 1.75em;
  }
}

.container {
  @extend %row;
  width: 94%;
  max-width: $site-max-width;
  margin: 0 auto;
}

.sitenav {
  position: fixed;
  top: 0;
  width: 100%;
  height: $nav-height;
  font-weight: 500;
  line-height: $nav-height;
  z-index: 100;
  background: $brand-primary;
  color: $text-on-dark;

  .container {
    width: 98%;
    max-width: 100%;
  }

  .brand {
    @extend %col;
    @include column(3);

    @extend %ir;
    margin-top: 8px;
    height: 26px;
    min-width: 179px;
    background: url("../img/logo_white.png") no-repeat 0 0;
    background: none, url("../img/logo_white.svg") no-repeat 0 0;
  }
  ul {
    @include column(9);
    text-align: right;

    @media screen and (max-width: $medium) {
      text-align: left;
    }
  }

  @media screen and (max-width: $medium) {
    position: static;
    height: auto;

    .brand {
      margin-left: 3%;
      width: auto;
    }
  }
}

nav ul {
  margin: 0;
  padding: 0;
  @extend %col;

  li {
    display: inline;
    margin: 0 1em 0 0;
  }
  li:last-of-type {
    margin-right: 0;
  }
  .sitenav»signup .button {
    padding: 8px 12px 7px;
    white-space: nowrap;
  }

  @media screen and (max-width: $medium) {
    line-height: 2;
    padding-bottom: 8px;
    text-align: left;
  }
}

header {
  padding-top: 3rem;
}

.sitefooter {
  width: 100%;
  background: $brand-primary;
  color: $text-on-dark;
  margin-top: 4.5rem;
  padding-top: 2em;
  padding-bottom: 4em;

  .signup-box + & {
    margin-top: 0;
  }

  dl {
    @extend %col;
    @include column(3);

    dt {
      font-weight: 700;
      margin-bottom: 5px;
    }

    dd {
      margin: 0;
    }

    a {
      display: inline-block;
      text-decoration: none;
      margin-bottom: 1px;

      &:hover {
        opacity: .9;
      }
    }
  }
}

.signup-box {
  background: $brand-secondary;
  color: $text-on-dark;
  margin-top: 4.5rem;
  padding-top: 2em;
  padding-bottom: 3em;

  .feature-icon-list + & {
    // Expand this list if we have more elements that should sit flush with the signup box area
    margin-top: 0;
  }

  h2 {
    max-width: 100%;
  }

  h3 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25em;
  }

  .signup-details {
    @extend %col;
    @include column(6);
    padding: 0;
    margin: 2em 0;
    text-align: left;

    p {
      margin: 0 0 .5em;
    }
  }

  .signup-actions {
    @extend %col;
    @include column(6);
    text-align: center;
    padding-top: 2em;

    small {
      display: block;

      a {
        border-bottom: 1px solid rgba(255,255,255,.5);
      }
    }
  }

  .button›solo {
    @include prefix(box-shadow, 0 2px 8px rgba(0,0,0,.2), 0 0 1px 1px rgba(0,0,0,.1), inset 0 0 2px rgba(255,255,255,1));
    margin-top: .75em;
    margin-bottom: .5em;
    background: #fff;
    color: $brand-primary;
    width: 80%;
    padding: 1em 10px;
    margin-left: 10%;

    &:hover {
      @include gradient(#fff, lighten($brand-secondary-dilute, 15%));
      text-shadow: 0 1px 1px #fff;
    }
  }
}


/*=============================================================================
  Front page specifics
=============================================================================*/

#page›front {

  header {
    background: $brand-primary;
    color: $text-on-dark;
    padding: #{$nav-height * 2} 0 1em;

    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 1.5em;
      line-height: 1.2;

      @media screen and (max-width: $medium) {
        font-size: 1em;

        a {
          // white-space: nowrap;
        }

        br {
          display: none;
        }
      }

      strong {
        @include font-medium;
        display: block;
        font-size: 1.6em;
        line-height: 1.3;
      }
      a {
        border-color: rgba($text-on-dark, .4);
      }
    }
    .button›solo {
      margin-top: 1.5em;
      margin-bottom: 2em;
      background: darken($brand-primary,10%);
      @include prefix(box-shadow, 0 2px 0 darken($brand-primary,20%));
    }
  }

  .page›front»pitch {
    @extend %col;
    @include column(4);

    padding-top: 1em;
    padding-bottom: 2em;
    padding-left: 8px;
    padding-right: 8px;

    h3, p {
      color: $brand-secondary;
    }
    h3 {
      font-size: 1.25em;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.1em;
      line-height: 1.4;
      margin-top: 0;
    }
    .caption {
    }
    .caption p {
      color: $text-color;
    }
    .image {
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  .page›front»addons {
    padding-top: 2em;
    padding-bottom: 1em;
  }
}

.page›front»calendar {
  padding-top: 2em;
  padding-bottom: 2.5em;

  img {
    @include box-sizing(border-box);
    @include prefix(box-shadow, 0 6px 16px rgba(0,0,0,.2), 0 0 1px 1px rgba(0,0,0,.1));
    border-radius: 4px;
    display: block;
    margin-top: 2em;
    padding: 8px 7px;
    width: auto;
  }
}

.front-cta {
  background: $brand-secondary;
  color: #fff;
  padding: 2em 0;
//  text-align: center;

  .cta-intro {
    @extend %col;
    @include column(6);
  }

  .cta-button {
    @extend %col;
    @include column(6);
    padding-top: 1em;
  }

  h2 {
    max-width: 100%;
  }

  p {
    text-align: center;
  }

  .button›solo {
    @include prefix(box-shadow, 0 2px 8px rgba(0,0,0,.2), 0 0 1px 1px rgba(0,0,0,.1), inset 0 0 2px rgba(255,255,255,1));
    background: #fff;
    color: $brand-primary;
    width: 80%;
    padding: 1em 10px;
    margin-left: 10%;

    &:hover {
      @include gradient(#fff, lighten($brand-secondary-dilute, 15%));
      text-shadow: 0 1px 1px #fff;
    }
  }

  a.button + a {
    clear: both;
    display: block;
    border: none;
    padding: .3em 0;
    opacity: .8;
  }

  small {
    display: block;
  }
}

.feature-icon-list {
  @include prefix(box-shadow, inset 0 5px 5px rgba(0,0,0,.05));
  background: #f4f4f4;
  padding-top: 1.5em;
  padding-bottom: 2em;

  .feature-intro {
    @extend %col;
    @include column(4);

    p {
      color: #888;
      padding-right: 8px;
    }
  }
}

.feature-section {
  padding-top: $nav-height;

  .big-title {
    background: rgba(255,255,255,.875);
    top: 2.8rem;
    padding-top: .33em;
    padding-bottom: .2em;
    margin-top: 0;
    z-index: 20;
  }
}

.feature-items {
  @extend %col;
  @include column(8);
  padding-left: 0;

  #page›features & {
    width: 100%;

    li {
      @include column(4);
//      @include offset(2);

      a {
        display: block;

        &::after {
          content: '›';
          display: block;
          float: right;
          line-height: 2;
          opacity: .5;
        }

        i {
          @include prefix(transition, all .1s ease-in);
        }

        &:hover {
          color: $brand-primary;

          i {
            @include prefix(transform, scale(1.25));
          }
        }
      }

      &:nth-of-type(odd) {
        clear: none;
      }

      &:nth-of-type(even) {
//        margin-left: 0;
      }
    }
  }

  li {
    @extend %col;
    @include column(6);
    margin-left: 0;

    list-style: none;
    padding-right: 16px;

    &:nth-of-type(odd) {
      clear: left;
    }

    h3 {
      color: $text-color;
      font-weight: 500;
      margin: 8px 0 4px;
    }

    p {
      margin-top: 0;
      color: #888;
    }

    i {
      color: $brand-secondary;
      display: inline-block;
      font-size: 22px;
      line-height: 36px;
      margin: 0 .2em 0 0;
      position: relative;
//      padding: 6px;
      width: 36px;
      height: 36px;
      text-align: center;
      background: #fff;
      border-radius: 50%;
    }
  }
}


/*=============================================================================
  Feature page specifics
=============================================================================*/

.featurenav {
  width: 100%;
  line-height: $nav-height;
  color: $text-on-dark;
  min-height: $nav-height;
  background: $brand-secondary;

  ul {
    @include column(12);
    text-align: center;

    li {
      font-size: 0.9em;
      white-space: nowrap;
    }

    @media screen and (max-width: $medium) {
      text-align: left;
    }
  }
}

#page›features {
  h2 {
    i {
      color: $brand-secondary;
      margin-right: .5em;
    }
  }

  div.feature-item {
    @extend %row;

    &:not(:last-of-type)::after {
      clear: both;
      color: $brand-secondary;
      content: '• • •';
      display: block;
      margin: 0 auto;
      padding: 3rem 0 0;
      text-align: center;
      width: 5rem;
    }
  }

  .caption {
    @extend %col;
    @include column(6);
  }

  .figure {
    @extend %col;
    @include column(6);
  }
}

#page›features header {
  background: #f4f4f4;
  padding-bottom: 0;

  .feature-intro-header {
    @extend %row;
    margin: 0;
    padding-top: 1rem;

    li {
      @extend %col;
      @include column(4);
      list-style: none;
      margin: 0;
      padding: 0;

      h2 {
        margin: 0;
      }

      a {
        @include gradient(rgba(0,0,0,0), rgba(0,0,0,.033));
        @include prefix(transition, all .125s ease-in-out);
        background-position: 0 3rem;
        background-repeat: repeat-x;
        border: 0;
        border-left: 1px solid rgba(255,255,255,.5);
        border-right: 1px solid rgba(0,0,0,.15);
        display: block;
        padding: 1rem 0 1.5rem;
        text-align: center;
        text-shadow: 0 1px 0 #f4f4f4;

        &:hover {
          background-position: 0 0;
        }
      }

      &:first-of-type a {
        border-left: 0;
      }

      &:last-of-type a {
        border-right: 0;
      }
    }
  }

  h1 {
    color: $brand-primary;
    margin-bottom: .25em;
    margin-top: 1em;
  }

  p.intro-text {
    @extend %col;
    @include column(6);
  }
  p.intro-cta {
    @extend %col;
    @include column(6);

    .button {
      width: 100%;
      margin: 1.5em 0 0;
    }
    .button-appendix small {
      clear: both;
      display: block;
      border: none;
      padding: .3em 0;
      opacity: .8;

      text-align: center;
    }
  }
}

.item-grid {
  @extend %row;

  .item {
    @extend %col;
    @include column(4);
  }
}

div[class*="page›features"] {
  padding-top: $nav-height;
}

.image»single {

}

.image»gallery ul {
  list-style-type: none;
  display: inline-block;
}

.image»gallery li {
  float: left;
  margin: 5px;
}

.evensmaller {
  font-size: 0.6em;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.hyphen {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

img.border {
  border: 2px solid rgba($text-color, .4);
}

/*=============================================================================
  Pricing page specifics
=============================================================================*/

#page›pricing {
  .button›solo.big {
    @include prefix(box-shadow, 0 2px 0 darken($brand-primary,20%));
    background: $brand-primary;
    font-size: 2rem;
    margin-top: 1rem;

    & + small {
      clear: left;
      display: block;
      padding: 1rem;
      opacity: .66;
      text-align: center;
    }
  }
}

.pricing-promo {
  h3 {
    color: $brand-primary;
    font-size: 1.5rem;
    line-height: 1.1;
    margin: .25rem 0 .5rem;
  }

  p {
    margin: 0 0 .5rem;
  }

  li {
    list-style: none;
    margin: 0;
  }

@media screen and (min-width: $medium) {
    @include prefix(box-shadow, 0 8px 16px rgba(0,0,0,.075));
    border-radius: 12px;
    display: table;

    li {
      display: table-cell;
      padding: 1.25rem 1.25rem 1rem;
      border: 1px solid #bbb;
      border-width: 1px 0 1px 1px;
      width: 33.33%;

      &:first-of-type {
        border-radius: 12px 0 0 12px;
      }
      &:last-of-type {
        border-width: 1px;
        border-radius: 0 12px 12px 0;
      }
    }
  }
}

.pricing-promo + .smallprint {
  margin: 0 0 1rem;
  opacity: .66;
  text-align: center;
}

#integration_categories {
  li {
    display: inline-block;
  }
  li.active a {
    text-decoration: underline;
  }
}

#integrations div {
  display:flex;
  justify-content:center;
  align-items:center;
  margin: 10px 40px;
  height: 128px;
  width: 128px;
}

#integration_details footer {
  margin-top: 100px;
}

#integration_details footer p {
  border: 1px solid;
  padding: 15px;
  display: inline-block;
}
